import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import './PDFViewer.css'; // Import your CSS file

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PDFViewer = ({ pdfURL, propPageNumber }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault(); // Prevent the default context menu
  };
  useEffect(() => {
    if(propPageNumber)
      setPageNumber(propPageNumber);
  }, [propPageNumber]);

  const onNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const onPrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const onPageNumberChange = (e) => {
    const value = e.target.value;
    const pageNum = parseInt(value, 10);

    if (value === '' || (pageNum > 0 && pageNum <= numPages)) {
      setPageNumber(pageNum);
    }
  };

  return (
    <div onContextMenu={handleContextMenu} className="pdf-container">
      <div className="page-info">
        Page{' '}
        <input
          type="number"
          value={pageNumber}
          onChange={onPageNumberChange}
          min="1"
          max={numPages}
          className="page-number-input"
        />{' '}
        of {numPages}
      </div>
      <Document file={pdfURL} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
        <Page pageNumber={pageNumber} renderMode="canvas" />
      </Document>
      <div className="pdf-navigation">
        <button className="prev-button" onClick={onPrevPage} disabled={pageNumber <= 1}>
          &#9664; {/* Left arrow */}
        </button>
        <button className="next-button" onClick={onNextPage} disabled={pageNumber >= numPages}>
          &#9654; {/* Right arrow */}
        </button>
      </div>
    </div>
  );
};

export default PDFViewer;
